import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../helpers/AuthContext";
import Slide from "../component/SlidesVideos/slide";


const PostsPageWork = () => {
  let history = useHistory();
  const { authState } = useContext(AuthContext);
  const [refreshed, setRefreshed] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      history.push("/");
    }

 /*    const handleLeavePage = () => {
      // Afficher la fenêtre de confirmation personnalisée
      const confirmation = window.confirm("Suite à un Refresh effectué de la page, voulez-vous quitter ?");
      if (confirmation) {
        window.location.href = "/"; // Rediriger vers la page d'accueil si l'utilisateur clique sur "OK"
      } else {
        setRefreshed(true); // Mettre à jour l'état pour déclencher le second effet uniquement après le refresh
      }
    };

    // Vérifier si la page a été rechargée ou fermée
    if (performance.navigation.type === 1) {
      handleLeavePage();
    } */

    return () => {};
  }, [authState, history]);

  useEffect(() => {
    if (refreshed) {
      window.location.href = "/"; // Rediriger vers la page d'accueil après le refresh
    }
  }, [refreshed]);

  return (
    <div className="container"
    
    style={{
      backgroundImage: `url('${authState.urlcontextbackground}')`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    }}>
      <NavLink
        className={({ isActive }) => (isActive ? "active" : "white")}
        to={`/postpriv/${authState ? authState.id : null}`}
      >
        <h1 className="classh1">Vos Posts de travail</h1>
      </NavLink>
      <Slide urlplay={"posts/byuserIdPriv"} />
    </div>
  );
};

export default PostsPageWork;
