import React, { useState, useContext, useRef, useEffect } from "react";
import { AuthContext } from "../helpers/AuthContext"; // Assurez-vous que le chemin d'accès est correct
import notea from "../logos/notea.png";
import noteb from "../logos/noteb.png";
import enfant from "../logos/personnage2.png";
import Piano from "./component/piano";
import OscillationLine from "./component/OscillationLine";
import Slideaccueil from "./component/SlidesVideos/slideaccueil";
import SetBackground from "./component/SetBackground";
import backgroundbase from "../logos/garcon-regardant-lune-dans-nuit2.jpg";
import background2 from "../logos/gifanimed1.gif";
import background3 from "../logos/gifanimed2.gif";
import background4 from "../logos/animed.png";

function Accueil() {
  const elementRef = useRef(null);
  const [isHidden, setIsHidden] = useState(false);
  const [isAnimationActive, setIsAnimationActive] = useState(false);
  const [playedFrequencies, setPlayedFrequencies] = useState([]);
  const { authState, setAuthState } = useContext(AuthContext); // Assurez-vous d'importer setAuthState

  function toggleVisibility() {
    setIsHidden((prevState) => !prevState);
    setIsAnimationActive(false);
    setPlayedFrequencies([]);
    setTimeout(() => {
      setIsAnimationActive(true);
    }, 3000);
  }

  useEffect(() => {
    const savedBackgroundUrl = localStorage.getItem("listbackground");
    if (savedBackgroundUrl && !authState.urlcontextbackground) {
      const backgroundMap = {
        option1: backgroundbase,
        option2: background2,
        option3: background3,
        option4: background4,
      };
      const resolvedBackground = backgroundMap[savedBackgroundUrl] || savedBackgroundUrl;
      setAuthState((prevState) => ({
        ...prevState,
        urlcontextbackground: resolvedBackground,
      }));
    }
  }, [authState.urlcontextbackground, setAuthState]);

  useEffect(() => {
    // Détecte si l'utilisateur est sur un appareil mobile
    const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);
    if (isMobile) {
      setIsHidden(true);
    }
  }, []);

  return (
    <div className="container">
      <img src={enfant} className="enfant" alt="Enfant" />

      <div className={`transition ${isHidden ? "hidden" : ""}`}>
        <div className="piano visible" ref={elementRef}></div>
        <Piano />
      </div>

      <h1 className="animdiv">
        <div>1</div>
        <div>D</div>
        <div>R</div>
        <div>E</div>
        <div>A</div>
        <div>M</div>
        <div>2</div>
        <div>P</div>
        <div>I</div>
        <div>A</div>
        <div>N</div>
        <div onClick={toggleVisibility}>
          {" "}
          <OscillationLine
            frequencies={Array.from(playedFrequencies)}
            setPlayedFrequencies={setPlayedFrequencies}
          />
        </div>
        <div>S</div>
      </h1>

      <div className="bird-container bird-container--one"></div>

      <div className="bird-container bird-container--two">
        <div className="bird bird--two">
          <div>
            <img
              src={notea}
              height="100px"
              width="50px"
              className="noteoiseau"
              alt="Note A"
            />
          </div>
        </div>
      </div>

      <div className="bird-container bird-container--three">
        <div className="bird bird--three"></div>
      </div>

      <div className="bird-container bird-container--four">
        <div className="bird bird--four"></div>
      </div>

      <div className="bird-container bird-container--five">
        <div className="bird bird--five">
          <img
            src={noteb}
            height="100px"
            width="100px"
            className="noteoiseau2"
            alt="Note B"
          />
        </div>
      </div>

      <Slideaccueil urlplay={"posts"} />

      {/* Intégration du composant SetBackground */}
      <SetBackground />
    </div>
  );
}

export default Accueil;
