import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { apiUrl } from "../../config";
import { AuthContext } from "../../helpers/AuthContext";

function PlayerNumber() {
  const [selectedPlayer, setSelectedPlayer] = useState(null);
 
  const { authState } = useContext(AuthContext); 

  const [idMeta, setidMeta] = useState("");
 

 
  

  const handleBookSelection = (event) => {
    const PlayerId = parseInt(event.target.value);

    setSelectedPlayer(PlayerId);
 if (PlayerId === 1) {
      const myplayerKey = "byuserIdpriv";
      const idMeta = authState.id;
    
      localStorage.setItem('myplayer', myplayerKey); // Enregistrer le player
      localStorage.setItem('myid', idMeta); // Enregistrer l'ID de l'utilisateur
     
    } else if (PlayerId === 2) {
        const myplayerKey = "byuserId";
        const idMeta = authState.id;
      
        localStorage.setItem('myplayer', myplayerKey); // Enregistrer le player
        localStorage.setItem('myid', idMeta); // Enregistrer l'ID de l'utilisateur
    } else if (PlayerId === 3) {
        const myplayerKey = "byuserIdpriv";
        const idMeta = authState.id;
      
        localStorage.setItem('myplayer', myplayerKey); // Enregistrer le player
        localStorage.setItem('myid', 1); // Enregistrer l'ID de l'utilisateur
    }

  
  };

  return (
    <div>
      <span className='textplayernumber'>--Choisissez les vidéos que vous voulez voir affichées sur la page accueil pour CE NAVIGATEUR-- </span>
      {/* Liste déroulante pour choisir le livre */}
      <div  className='textplayernumberoption'>
      <select onChange={handleBookSelection}>
        <option value="">Accueil : Choisir les posts vidéos que vous voulez voir apparaitre</option>
        <option value="1">1-Mes posts de travaux</option>
        <option value="2">2-Mes posts persos</option>
        <option value="3">3-Les posts de mon professeurs</option>
      </select>
      </div>
    
    
    </div>
  );
}

export default PlayerNumber;
