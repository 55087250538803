import React, { useState, useContext, useEffect, useRef } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";
import Loader from "./component/Loader";
import { apiUrl } from "../config";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [Email, setEmail] = useState("");
  const [identifiants, setidentifiants] = useState(false);
  const [Isloading, setIsloading] = useState(false);
  const { setAuthState } = useContext(AuthContext);
  let history = useHistory();
  const usernameInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const loginButtonRef = useRef(null);

  useEffect(() => {
    // Définir le focus initial sur le bouton Login
    if (loginButtonRef.current) {
      loginButtonRef.current.focus();
    }
  }, []);

  const handleInputBlur = () => {
    // Réinitialiser le focus sur le bouton Login après un délai court
    setTimeout(() => {
      if (loginButtonRef.current) {
        loginButtonRef.current.focus();
      }
    }, 10);
  };

  const login = async () => {
    setIsloading(true);
    const data = { username: username, password: password };
    try {
      const response = await axios.post(`${apiUrl}/auth/login`, data);
      if (response.data.error) {
        setIsloading(false);
        alert(response.data.error);
      } else {
        localStorage.setItem("accessToken", response.data.token);
        setAuthState({
          username: response.data.username,
          photo_profil: response.data.photo_profil,
          id: response.data.id,
          admin: response.data.admin,
          prof: response.data.prof,
          status: true,
        });
        setIsloading(false);
        history.push("/PostsPage");
      }
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  const send = async () => {
    const data = { email: Email };
    try {
      const response = await axios.post(`${apiUrl}/send/recup`, data);
      window.alert(`Un message à été envoyé à l'adresse ${Email}`);
      setidentifiants(!identifiants);
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  const resend = async () => {
    setidentifiants(!identifiants);
  };

  return (
    <div className="containerlogin">
      <span className="containerlog">
        <div className="input">
          <input
            ref={usernameInputRef}
            placeholder="Pseudo"
            type="text"
            onChange={(event) => setUsername(event.target.value)}
            onBlur={handleInputBlur}
          />
        </div>
        <div className="input">
          <input
            ref={passwordInputRef}
            placeholder="Password"
            type="password"
            onChange={(event) => setPassword(event.target.value)}
            onBlur={handleInputBlur}
          />
        </div>
      </span>
      <span className="loginbutton">
        {!identifiants && (
          <button onClick={login} ref={loginButtonRef}>
            Login
          </button>
        )}

        <button onClick={resend}>
          {!identifiants && <span id="forget">Identifiants Oubliés</span>}
          {identifiants && <span id="forget">Retour connexion</span>}
        </button>
      </span>
      {identifiants && (
        <>
          <div>
            <input
              className="inform-email"
              placeholder="1-Indiquer un email "
              type="Email"
              onChange={(event) => setEmail(event.target.value)}
              onBlur={handleInputBlur}
            />
            <button onClick={send}>Envoyer</button>
          </div>
          <div>
            <input
              placeholder="2-Coller le token obtenu par email"
              type="text"
              onChange={(event) => {
                localStorage.setItem("accessToken", event.target.value);
                history.push("/");
                window.location.reload();
              }}
              onBlur={handleInputBlur}
            />
          </div>
        </>
      )}
      {Isloading && <Loader />}
    </div>
  );
}

export default Login;
