import React, { useEffect, useState, useRef } from "react";

const OscillationLine = ({ frequencies }) => {
  const canvasRef = useRef(null);
  const amplitude = 50;
  const animationSpeed = 0.02;
  const animationAmplitude = 5;
  const lineSpacing = 0.5;
  const ctx = canvasRef.current && canvasRef.current.getContext("2d");
  const [animate, setAnimate] = useState(true);
  const [animationPlayed, setAnimationPlayed] = useState(false);

  // Fonction pour dessiner les sinusoides
  const drawSinusoids = (x, y, frequency, phase, canvas, index) => {
    const frequencyAmplitude = amplitude * (1 - frequency / 1000);
    const frequencyOffset = animationAmplitude * Math.sin(phase * (frequency / 100));
    ctx.beginPath();

    // Calculate perspective scale based on the index
    const perspectiveScale = 1 - index * 0.05;

    const adjustedY = y + (canvas.height / 2) * (1 - frequency / 1000);
    ctx.moveTo(x, adjustedY + frequencyOffset);

    for (let i = 0; i < canvas.width; i++) {
      const angle = (i / canvas.width) * Math.PI * 2;
      const yCoord =
        adjustedY +
        Math.sin(angle * frequency) * frequencyAmplitude * perspectiveScale +
        frequencyOffset;
      ctx.lineTo(i, yCoord);
    }

    ctx.strokeStyle = "gold";
    ctx.lineWidth = 2 * perspectiveScale; // Adjust line width based on the perspective scale

    // Add golden shadow effect
    ctx.shadowBlur = 15 * perspectiveScale; // Adjust the shadow blur based on the perspective scale
    ctx.shadowColor = "black"; // Golden shadow color

    ctx.stroke();

    // Reset shadow settings
    ctx.shadowBlur = 0;
    ctx.shadowColor = "transparent";
  };

  // Fonction pour animer le canvas
  const animateCanvas = () => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    if (animate && !animationPlayed) {
      const phase = Date.now() * animationSpeed;

      for (let i = 0; i < frequencies.length; i++) {
        const freq = frequencies[i];
        let x = ctx.canvas.width / 2;
        let y =
          i * (ctx.canvas.height / (frequencies.length - 1 + lineSpacing)) -
          lineSpacing * i * (ctx.canvas.height * 0.01); // Corrected the spacing between lines
        drawSinusoids(x, y, freq, phase, ctx.canvas, i);
      }

      requestAnimationFrame(animateCanvas);
    }
  };

  useEffect(() => {
    if (!ctx) return;

    ctx.canvas.width = window.innerWidth;
    ctx.canvas.height = window.innerHeight;

    animateCanvas();

    return () => {
      // Nettoyer la boucle d'animation lorsque le composant est démonté
      setAnimate(false);
    };
  }, [frequencies, ctx]);

  // Gestionnaire pour l'appui sur une touche
  const handleKeyPress = () => {
    setAnimationPlayed(true);
    setAnimate(false);
  };

  // Gestionnaire pour la relâche d'une touche
  const handleKeyRelease = () => {
    setAnimationPlayed(false);
    setAnimate(true);
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    window.addEventListener("keyup", handleKeyRelease);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
      window.removeEventListener("keyup", handleKeyRelease);
    };
  }, []);

  return <canvas ref={canvasRef} className="oscillation" />;
};

export default OscillationLine;
