import React, { useEffect, useContext } from "react";
import "swiper/swiper-bundle.min.css";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../helpers/AuthContext";
import Slide from "../component/SlidesVideos/slide";

const PostsPerso = () => {
  let history = useHistory();

  const { authState } = useContext(AuthContext);

  const id = authState.id;

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      history.push("/login");
    }
  }, [id, history]);

  return (
    <div className="container">
      <NavLink
        className={({ isActive }) => (isActive ? "active" : "white")}
        to={`/profile/${authState.id}`}
      >
        <h1 className="classh1">Vos Posts Personnels</h1>
      </NavLink>

      <Slide urlplay={"posts/byuserId"} />
    </div>
  );
};

export default PostsPerso ;
