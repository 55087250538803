import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Fiche from "./Fiche";
import { AuthContext } from "../helpers/AuthContext";
import { apiUrl } from "../config";

const ComposantPorte = () => {
  let history = useHistory();

  const { authState } = useContext(AuthContext);
  const [listOfPosts, setListOfPosts] = useState([]);
  const [checked, setChecked] = useState(false);

  const [NoChecked, setNoChecked] = useState(false);

  const [checkedbase, setcheckedbase] = useState(true);
  const prof = authState.prof;
  const [OK, setOK] = useState(false);
  const [OK2, setOK2] = useState(false);
  const [ListOfUsers, setListOfUsers] = useState([]);
  const [Identifiant, setIdentifiant] = useState(authState.id);
  const handleChange = (event) => {
    setChecked((checked) => !checked);
    console.log("checked");
    console.log(checked);
  };
  useEffect(() => {
    axios.get(`${apiUrl}/send/list`).then((response) => {
      setListOfUsers(response.data);
    });
  }, []);
  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      history.push("/login");
    }

    axios.get(`${apiUrl}/posts3/byuserId/${Identifiant}`).then((response) => {
      setListOfPosts(response.data);
    });
  }, [OK, history, authState.id, checked, Identifiant]);

  const ModifPost = async (listOfPost) => {
    try {
      if (listOfPost.checked) {
        const initalisevaleur1 = listOfPost.checked;
      } else {
        const initalisevaleur0 = listOfPost.checked;
      }

      if (listOfPost.checked === 1) {
        await axios.put(
          `${apiUrl}/posts3/checked`,
          {
            checked: 0,
            id: listOfPost.id,
          },
          {
            headers: { accessToken: localStorage.getItem("accessToken") },
          }
        );

        setOK((OK) => !OK);
      } else {
        await axios.put(
          `${apiUrl}/posts3/checked`,
          {
            checked: 1,
            id: listOfPost.id,
          },
          {
            headers: { accessToken: localStorage.getItem("accessToken") },
          }
        );

        setOK((OK) => !OK);
      }

      return;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {prof && (
        <select
          value={Identifiant}
          onChange={(f) => setIdentifiant(f.target.value)}
          required
        >
          <option value="" disabled>
            Sélectionnez un utilisateur
          </option>
          {ListOfUsers.map((user) => (
            <option
              key={user.id}
              value={user.id}
              onChange={() => setIdentifiant(user.id)}
            >
              {user.username} : {user.email}
            </option>
          ))}
        </select>
      )}

      <Fiche
        id={authState.id}
        listOfPosts={listOfPosts}
        OK={OK}
        setOK={setOK}
        OK2={OK2}
        setOK2={setOK2}
        history={history}
        setChecked={setChecked}
        setNoChecked={setNoChecked}
        checked={checked}
        NoChecked={NoChecked}
        checkedbase={checkedbase}
        setcheckedbase={setcheckedbase}
        ModifPost={ModifPost}
        handleChange={handleChange}
        photo={authState.photo_profil}
      />

      <p>Activités</p>
    </>
  );
};
export default ComposantPorte;
