import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../helpers/AuthContext";
import backgroundbase from "../../logos/garcon-regardant-lune-dans-nuit2.jpg";
import background2 from "../../logos/gifanimed1.gif";
import background3 from "../../logos/gifanimed2.gif";
import background4 from "../../logos/animed.png";

function SetBackground() {
  const [selectedOption, setSelectedOption] = useState("");
  const [customUrl, setCustomUrl] = useState("");
  const [storedUrls, setStoredUrls] = useState([]);
  const { authState, setAuthState } = useContext(AuthContext);
  const [urlbackground, setUrlBackground] = useState(backgroundbase);

  useEffect(() => {
    const listBackground = localStorage.getItem("listbackground");
    if (listBackground) {
      setSelectedOption(listBackground);
      setUrlBackgroundFromOption(listBackground);
    } else {
      setSelectedOption("option1");
      setUrlBackground(backgroundbase);
    }
    const storedUrls = localStorage.getItem("storedUrls");
    if (storedUrls) {
      setStoredUrls(JSON.parse(storedUrls));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("listbackground", selectedOption);
    if (selectedOption !== "custom") {
      setUrlBackgroundFromOption(selectedOption);
    }
  }, [selectedOption]);

  useEffect(() => {
    setAuthState(prevState => ({
      ...prevState,
      urlcontextbackground: urlbackground
    }));
  }, [urlbackground, setAuthState]);

  const setUrlBackgroundFromOption = (option) => {
    switch (option) {
      case "option1":
        setUrlBackground(backgroundbase);
        break;
      case "option2":
        setUrlBackground(background2);
        break;
      case "option3":
        setUrlBackground(background3);
        break;
      case "option4":
        setUrlBackground(background4);
        break;
      default:
        setUrlBackground(option);
        break;
    }
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleCustomUrlChange = (event) => {
    setCustomUrl(event.target.value);
    if (event.target.value.trim() !== "") {
      setUrlBackground(event.target.value);
    }
  };

  const handleCustomUrlSubmit = (event) => {
    event.preventDefault();
    if (customUrl.trim() !== "") {
      const newUrls = [...storedUrls, customUrl];
      setStoredUrls(newUrls);
      localStorage.setItem("storedUrls", JSON.stringify(newUrls));
      setSelectedOption(customUrl);
      setCustomUrl("");
    }
  };

  const handleDeleteCustomUrl = (index) => {
    const newStoredUrls = storedUrls.filter((_, i) => i !== index);
    setStoredUrls(newStoredUrls);
    localStorage.setItem("storedUrls", JSON.stringify(newStoredUrls));
  };

  return (
    <>
      <select
        className="selectbackground"
        value={selectedOption}
        onChange={handleSelectChange}
      >
        <option value="option1" className="school">School</option>
        <option value="option2" className="night">Night</option>
        <option value="option3" className="mouv">Mouv</option>
        <option value="option4" className="dream">Dream</option>
        {storedUrls.map((url, index) => (
          <option key={index} value={url}>
            {`MyBackground ${index + 1}`}
          </option>
        ))}
        <option value="custom" className="ajouter">Ajouter / Supprimer</option>
      </select>

      {selectedOption === "custom" && (
        <>
          <div className="selectbacurl">
            <ul className="selectbacurlsupr">
              {storedUrls.map((url, index) => (
                <li key={index}>
                  {`MyBackground ${index + 1}`}
                  <button onClick={() => handleDeleteCustomUrl(index)}>Supprimer</button>
                </li>
              ))}
            </ul>
            <form onSubmit={handleCustomUrlSubmit}>
              <input
                type="text"
                value={customUrl}
                onChange={handleCustomUrlChange}
                placeholder="Entrez l'URL personnalisée"
              />
              <button type="submit">Ajouter</button>
            </form>
          </div>
        </>
      )}
    </>
  );
}

export default SetBackground;
