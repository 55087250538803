import React, { useEffect, useState } from "react";

function NotesBlanches({ imp, freq, touch, oscil, playSound }) {
  const [isActive, setIsActive] = useState(false);

  const playNote = () => {
    setIsActive(true);
    const context = new AudioContext();

    const gainNode = context.createGain();
    const convolverNode = context.createConvolver();
    const oscillator = context.createOscillator();
    oscillator.type = oscil;

    const impulseResponse = [imp];
    const frequence = freq;

    oscillator.frequency.setValueAtTime(
      parseFloat(frequence),
      context.currentTime
    );

    oscillator.connect(gainNode);
    gainNode.connect(context.destination);
    oscillator.connect(convolverNode);
    convolverNode.connect(context.destination);
    convolverNode.buffer = createImpulseResponse(context, impulseResponse, 1);

    oscillator.start();
    gainNode.gain.setValueAtTime(0.5, context.currentTime);
    gainNode.gain.exponentialRampToValueAtTime(
      0.0001,
      context.currentTime + 1
    );

    setTimeout(() => {
      oscillator.stop();
      setIsActive(false);
    }, 1000);
  };

  const touche = touch;

  const createImpulseResponse = (context, response, duration) => {
    const sampleRate = context.sampleRate;
    const length = sampleRate * duration;
    const impulse = context.createBuffer(1, length, sampleRate);
    const impulseData = impulse.getChannelData(0);

    for (let i = 0; i < length; i++) {
      impulseData[i] = response[i % response.length];
    }

    return impulse;
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === touche) {
        playNote();
        setIsActive(true);
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === touche) {
        setIsActive(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [oscil]);

  const handleMouseDown = () => {
    playNote();
    setIsActive(true);
  };

  const handleMouseUp = () => {
    setIsActive(false);
  };

  return (
    <div>
      <button
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        className={`white ${isActive ? "active" : ""}`}
      ></button>
    </div>
  );
}

export default NotesBlanches;
