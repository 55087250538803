import React, {useEffect, useState } from "react";
import { useHistory } from "react-router-dom";



function Fiche({
  listOfPosts,
  checkedbase,
  ModifPost,
  handleChange,
  id,
  photo
}) {

  const [prof, SetProf] = useState(false);
  
  useEffect(() => {
  
    if( id === 1 ) {
  
      SetProf("true")
    
    }
 
    
  
   
  
  }, []);
  let history = useHistory();

  const orderDisplayPosts = 
  listOfPosts && listOfPosts.sort((a,b)=> {
    
  
  return (b.id - a.id
  
  
  
  )
  
  
  });
  return (
    <div>
      <div className="photofiche"><img src={photo}/></div>
      <div className="Objectifs">Liste de mes objectifs atteinds
      <ol>
        <li>1</li>
        <li>2</li>
        <li>3</li>
        </ol></div>
      <div className="ficheAdmin">
        <h1> ❎ Liste des Objectifs ✅ </h1>
     
        
          
            <>
              <div className="listepostsparutilisateur2">
                {orderDisplayPosts.map((value, key) => {
                  const date = new Date(value.createdAt);
                  return (<>
                    <div key={key} className="post3">
                      <div
                        className="title"
                        onClick={() => {
                          history.push(`/lecture/${value.id}`);
                        }}
                      >
                        {" "}
                        Mon objectif : {value.title}{" "}
                      </div>
                      <div
                        className="body"
                        onClick={() => {
                          history.push(`/lecture/${value.id}`);
                        }}
                      >
                        Descritpion : {value.postText}

                 
                      </div>
                     
                      <div>
                          

                         
                      <div>
                     
                        </div>
                        
                      </div>

                      
                    </div>
                    <div className="chekbox">Date de création : 
                     {new Intl.DateTimeFormat("local").format(date)}
                    
 <div>Détails : {value.lien}</div>
                     {value.checked && <div>👍😃 </div>}
                     {checkedbase &&  prof &&(
                       <input
                         type="checkbox"
                         checked={value.checked}
                         onClick={() => ModifPost(value)}
                         onChange={handleChange}
                         id={listOfPosts.id}
                       />
                     )}
                     {!value.checked && prof && (
                       <div>
                         Cochez la case si votre objectif est atteint{" "}
                       </div>
                     )}
                   </div>
                     </>
                  );
                })}
              </div>
            </>
       
        </div>
      </div>
  );
}
export default Fiche;
