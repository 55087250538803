//import des routes
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { createBrowserHistory } from "history";
import { AuthContext } from "./helpers/AuthContext";
import { useState, useEffect } from "react";
import axios from "axios";

//import des pages

import "./css/style.css";
import Post from "./pages/Post";
import Post2 from "./pages/Post2";
import Post3 from "./pages/Post3";
import lecture from "./pages/lecture";
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import Fiche from "./pages/Fiche";
import Profile from "./pages/Profile";
import ChangePasswordreq from "./pages/ChangePasswordreq";
import Delete from "./pages/Delete";
import Accueil from "./pages/Accueil";
import Postpriv from "./pages/Postpriv";
import PostsPerso from "./pages/PagesForSeeVideos/PostsPerso";
import FicheAdmin from "./pages/FicheAdmin";
import PostPro from "./pages/PostPro";
import Backgroundbase from "./logos/garcon-regardant-lune-dans-nuit2.jpg";
import PostsPageWork from "./pages/PagesForSeeVideos/PostsPageWork";

import CreerPosts from "./pages/CreerPosts";
import PostsProfesseur from "./pages/PagesForSeeVideos/PostsProfesseur";

import ComposantPorte from "./pages/ComposantPorte";

import Apropos from "./pages/Apropos";

import { apiUrl } from "./config";

const history = createBrowserHistory();

function App() {
  const [menu, setMenu] = useState("...");
  const [isHidden, setIsHidden] = useState(false);
  const [authState, setAuthState] = useState({
    username: "",
    id: 0,
    photo_profil: "",
    prof: "",
    status: false,
    admin: "",
    urlcontextbackground: "",
  });

  function toggleVisibility() {
    setIsHidden((prevState) => !prevState);
  }

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      axios
        .get(`${apiUrl}/auth/auth`, {
          headers: {
            accessToken: accessToken,
          },
        })
        .then((response) => {
          if (response.data.error) {
            setAuthState({ ...authState, status: false });
          } else {
            setAuthState({
              username: response.data.username,
              id: response.data.id,
              admin: response.data.admin,
              prof: response.data.prof,
              photo_profil: response.data.photo_profil,
              status: true,
              urlcontextbackground: response.data.urlcontextbackground,
            });
          }
        });
    }
  }, []);

  const logout = () => {
    localStorage.removeItem("accessToken");
    setAuthState({
      username: "",
      prof: "",
      id: 0,
      photo_profil: "",
      admin: "",
      status: false,
      style: "",
      urlcontextbackground: "", // Assurez-vous de vider également l'URL de contexte ici si nécessaire
    });
    history.push("/");
  };

  const [style, setStyle] = useState("barredenavigation");



  // Déterminer l'image de fond à utiliser
  const backgroundImage = authState.urlcontextbackground
    ? `url(${authState.urlcontextbackground})`
    : `url(${Backgroundbase})`;

  return (
    <section
      className="container"
      style={{
        backgroundImage: backgroundImage,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <AuthContext.Provider value={{ authState, setAuthState }}>
        <Router>
          <>
            {
              <button
                id="bouton-cacher-log2"
                onClick={toggleVisibility}
                className={`transition ${!isHidden ? "hidden" : ""}`}
              >
                ...
              </button>
            }
            {!isHidden && (
              <nav className={style}>
                {authState.status && (
                  <>
                    <div className="log">
                      <div>
                        {authState.status && (
                          <button onClick={logout} id="deco_button">
                            ⚪ Déconnexion
                          </button>
                        )}
                      </div>
                      <li>
                        {!authState.prof && authState.username && (
                          <>
                            <Link to={`/FicheAdmin/${authState.id}`}>
                              🍀 {authState.username}
                            </Link>
                          </>
                        )}

                        {authState.prof && (
                          <>
                            <Link to={`/FicheAdmin/${authState.id}`}>
                              🍀 {authState.username}
                            </Link>
                          </>
                        )}
                      </li>
                      <li>
                        <>
                          {authState.prof && (
                            <div>
                              <Link to="/registration">💎 S'enregistrer</Link>
                            </div>
                          )}
                        </>
                      </li>

                      <li>
                        {authState.status && (
                          <Link to="/PostsPerso">​📜​Mes Posts </Link>
                        )}
                      </li>

                      <li>
                        {authState.status && (
                          <Link to="/PostsPageWork">​📜​Mes Posts travail</Link>
                        )}
                      </li>
                      <li>
                        {authState.status && (
                          <Link to="/CreerPosts">​🎵​Créer un post</Link>
                        )}
                      </li>
                      <li>
                        {authState.status && (
                          <Link to="/PostPro">☺️​Feed Professeur</Link>
                        )}
                      </li>

                      <div>
                        <Link to="/Accueil">
                          <div>🏛</div>
                          <div>Accueil</div>
                        </Link>
                      </div>

                      {
                        <button
                          id="bouton-cacher-log"
                          onClick={toggleVisibility}
                          className={`transition ${!isHidden ? "" : ""}`}
                        >
                          ...
                        </button>
                      }
                    </div>
                  </>
                )}

                {!authState.status && (
                  <>
                    <div className="log">
                      {
                        <button
                          id="bouton-cacher-log"
                          onClick={toggleVisibility}
                          className={`transition ${!isHidden ? "" : ""}`}
                        >
                          ...
                        </button>
                      }
                      <div>
                        <Link to="/login">
                          <div>☀️</div>
                          <div>Connexion</div>
                        </Link>
                      </div>

                      <div>
                        <Link to="/Accueil">
                          <div>🏛</div>
                          <div>Accueil</div>
                        </Link>
                      </div>

                      <div>
                        <Link to="/Apropos">
                          <div>🎹</div>
                          <div>A Propos</div>
                        </Link>
                      </div>
                    </div>
                  </>
                )}
              </nav>
            )}
          </>
          <Switch>
            <Route path="/FicheAdmin/:id" exact component={FicheAdmin} />
            <Route
              path="/changepasswordreq"
              exact
              component={ChangePasswordreq}
            />
            <Route path="/ComposantPorte" exact component={ComposantPorte} />
            <Route path="/PostsPageWork" exact component={PostsPageWork} />
            <Route path="/PostsPerso" exact component={PostsPerso} />
            <Route path="/PostPro" exact component={PostPro} />
            <Route path="/profile/:id" exact component={Profile} />
            <Route path="/postpriv/:id" exact component={Postpriv} />
            <Route path="/PostsProfesseur" exact component={PostsProfesseur} />
            <Route path="/Post/:id" exact component={Post} />
            <Route path="/postsecondaire/:id" exact component={Post2} />
            <Route path="/post3/:id" exact component={Post3} />
            <Route path="/CreerPosts" exact component={CreerPosts} />
            <Route path="/fiche/:id" exact component={Fiche} />
            <Route path="/lecture/:id" exact component={lecture} />
            <Route path="/delete" exact component={Delete} />
            <Route path="/registration" exact component={Registration} />
            <Route path="/login" exact component={Login} />
            <Route path="/Apropos" exact component={Apropos} />
            <Route path="/Accueil" exact component={Accueil} />
            <Route path="/App" exact component={Accueil} />
            <Route path="/" exact component={Accueil} />
            <Route path="*" exact component={Accueil} />
          </Switch>
        </Router>
      </AuthContext.Provider>
    </section>
  );
}

export default App;
