  export const apiUrl =  'https://1dream2pianos.net/app';
//Pour commit


/* https://1dream2pianos.net/app */
/*   http://127.0.0.1:3001 */
/*   http://127.0.0.1:3001/app */




