import React, { useState, useRef, useEffect } from "react";
import NotesBlanches from "../component/NotesBlanches";
import NotesNoir from "../component/NotesNoir";
import OscillationLine from "../component/OscillationLine";


function Piano ()  {
//masque le piano

 
 
  const [currentFrequency, setCurrentFrequency] = useState(null);

  const [playedFrequencies, setPlayedFrequencies] = useState([]);



  const [oscillatorType, setOscillatorType] = useState("string");

// Set touche noires 

const [DoDfreq,  setDoDfreq,] = useState(554.37);
const [ReDfreq,  setReDfreq,] = useState(311.13);
const [MiDfreq,  setMiDfreq] = useState(329.63);
const [Mi2Dfreq,  setMi2Dfreq,] = useState(659.25);
const [Re2dfreq,  setRe2dfreq] = useState(622.25);
const [SiD2freq,  setSiD2freq] = useState(493.88);
const [LaDfreq,  setLaDfreq] = useState(466.16);
const [SolDfreq,  setsolDfreq] = useState(415.30);



// Set touche Blanches
 const [Si2freq,  setSi2freq] = useState(987.77);
 const [La2freq,  setLa2freq] = useState(880);
 const [Sol2freq,  setSol2freq] = useState(783.99);
 const [Fa2freq,  setFa2freq] = useState(698.46);
 const [Mi2freq,  setMi2freq] = useState(659.25);
 const [Re2freq,  setRe2freq] = useState(587.33);
 const [Do2freq,  setDo2freq] = useState(523.25);

 const [Sifreq, setSifreq] = useState(493.88)
 const [Lafreq, setLafreq] = useState(440)
 const [Solfreq, setSolfreq] = useState(392)
 const [Fafreq, setFafreq] = useState(349.23);
 const [Mifreq, setMifreq] = useState(329.6);
 const [Refreq, setrefreq] = useState(293.66);
 const [Dofreq, setDofreq] = useState(261.63);


  // count -1 est égale au dernier post
  



//autres
const [OscilType, setOscilType] = useState("string");
 // Taille de l'écran pour cacher le piano

 const [lastPlayedFrequency, setLastPlayedFrequency] = useState(null);


 


function playSound(freq) {
    const context = new (window.AudioContext || window.webkitAudioContext)();
    const oscillator = context.createOscillator();
    setLastPlayedFrequency(freq);
    oscillator.type = oscillatorType;
    oscillator.frequency.setValueAtTime(freq, context.currentTime);
    oscillator.connect(context.destination);
    oscillator.start();
    oscillator.stop(context.currentTime + 0.3);
    setCurrentFrequency(freq);
    // Mettre à jour les fréquences jouées en utilisant le Set pour stocker les fréquences uniques
    setPlayedFrequencies((prevFrequencies) => new Set([...prevFrequencies, freq]));
  }


  return (
  
    
       
    

<div className="pianocomp">
    {/*  <button className="boutonpiano"onClick={oscillatortype}>Modifier le son</button> */}
<div className="taillepiano">
        {/*   Si2  -----------------------------------------------------------*/}
<div  ><NotesBlanches imp={0} freq={Si2freq} touch={"l"} playSound={playSound} /></div>

    {/*    Mi2d ---------------------------- */}
    <div  ><NotesNoir imp={0} freq={Mi2Dfreq} touch={"!"} oscil={OscilType}playSound={playSound}/></div>
          {/*   La2  -----------------------------------------------------------*/}
<div   ><NotesBlanches imp={0} freq={La2freq} touch={"k"} oscil={OscilType} playSound={playSound}/></div>
            {/*    Re2d ---------------------------- */}
   <div    ><NotesNoir imp={0} freq={Re2dfreq} touch={":"} oscil={OscilType}playSound={playSound}/></div>
          {/*   Sol2  -----------------------------------------------------------*/}
<div   ><NotesBlanches imp={0} freq={Sol2freq} touch={"j"} oscil={OscilType} playSound={playSound}/></div>



               {/*    Do2 ---------------------------- */}
   <div    ><NotesNoir imp={0} freq={Do2freq} touch={";"} oscil={OscilType} playSound={playSound}/></div>



          {/*   Fa2  -----------------------------------------------------------*/}
<div   ><NotesBlanches imp={0} freq={Fa2freq} touch={"h"} oscil={OscilType} playSound={playSound}/></div>
             {/*   Mi2  -----------------------------------------------------------*/}
<div  ><NotesBlanches imp={0} freq={Mi2freq} touch={"g"} oscil={OscilType} playSound={playSound}/></div>
                    {/*    SiD ---------------------------- */}
   <div    ><NotesNoir imp={0} freq={SiD2freq} touch={","} oscil={OscilType}playSound={playSound} /></div>
             {/*   Re2  -----------------------------------------------------------*/}
<div   ><NotesBlanches imp={0} freq={Re2freq} touch={"f"} oscil={OscilType} playSound={playSound}/></div>
                      {/*    Lad ---------------------------- */}
   <div  ><NotesNoir imp={0} freq={LaDfreq} touch={"n"} oscil={OscilType}playSound={playSound}/></div>
          
  {/*   Do2  -----------------------------------------------------------*/}
<div    ><NotesBlanches imp={0} freq={Do2freq} touch={"p"} oscil={OscilType} playSound={playSound}/></div>




         {/*   Si  -----------------------------------------------------------*/}
<div   ><NotesBlanches imp={0} freq={Sifreq} touch={"o"} oscil={OscilType}playSound={playSound}/></div>
                {/*    Sold ---------------------------- */}
   <div    ><NotesNoir imp={0} freq={SolDfreq} touch={"b"} oscil={OscilType}playSound={playSound}/></div>
        
  {/*   La  -----------------------------------------------------------*/}
<div   ><NotesBlanches imp={0} freq={Lafreq} touch={"i"} oscil={OscilType}playSound={playSound}/></div>
        {/*    Fad ---------------------------- */}
   <div    ><NotesNoir imp={0} freq={369.99} touch={"v"} oscil={OscilType}playSound={playSound}/></div>


          {/*   Sol  -----------------------------------------------------------*/}
<div  ><NotesBlanches imp={0} freq={Solfreq} touch={"u"} oscil={OscilType}playSound={playSound}/></div>



       {/*    MiD ---------------------------- */}
   <div   ><NotesNoir imp={0} freq={MiDfreq} touch={"c"} oscil={OscilType}playSound={playSound}/></div>
   


  {/*   Fa  -----------------------------------------------------------*/}
<div   ><NotesBlanches imp={0} freq={Fafreq} touch={"y"} oscil={OscilType}playSound={playSound}/></div>

  {/*   Mi  -----------------------------------------------------------*/}
<div    ><NotesBlanches imp={0} freq={Mifreq} touch={"t"} oscil={OscilType}playSound={playSound}/></div>



{/*    ReD ---------------------------- */}
   <div   ><NotesNoir imp={0} freq={ReDfreq} touch={"x"} oscil={OscilType}playSound={playSound}/></div>
{/*   Re  -----------------------------------------------------------*/}
<div    ><NotesBlanches imp={0} freq={Refreq} touch={"r"} oscil={OscilType}playSound={playSound}/></div>



{/*    DOD ---------------------------- */}
<div    ><NotesNoir imp={0} freq={DoDfreq} touch={"w"} oscil={OscilType}playSound={playSound}/></div>


{/* DO -----------------------------------------------------------*/}
<div   ><NotesBlanches imp={0} freq={Dofreq} touch={"e"} oscil={OscilType}playSound={playSound}/></div>
</div>

</div>

  )
}

export default Piano

