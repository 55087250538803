
import piano from "../logos/glace.jpg"
import photo from "../logos/ying.jpg"
import pianom from "../logos/pianom.jpg"


function Apropos() {


return (
    <div className="containerapropos">


 

     
       

      <div className="apropos">


      <div className="presentation">
     <div><img src={photo}className="piano"></img> 
     <div><img src={pianom}className="piano"></img>
     </div>
      </div>  
     <div>

      <div className="aproposbistext">
Bonjour, <br></br>
Je m'appelle Ying, <br></br>je voudrais vous aider<br></br> pour l'apprentissage du piano
</div>
</div>

</div>
<div className="aproposbis"> 
<h1>
Qu'est-ce qu'un bon apprentissage ? </h1><br></br>

J'ai débuter le piano à l'âge de cinq ans en Chine<br></br>

j'avais une pratique répéter d'apprentissage de plusieurs heures par semaine ce qui m'a immergé dans la culture de la musique classique. <br></br>
<br></br>

 je pense ainsi qu'il est important en plus de la pratique de s'imprégner de valeurs morales et émotionnelles en liens avec les différents morceaux qu'il est possible d'interpréter. 
 
 L'objectif alors est dans un premier temps d'accéder à la technique du piano par la pratique puis ensuite 
 à l'infinité de morceaux proposés.<br></br><br></br>
 Je serais alors ravi de choisir l'apprentissage qui vous correspondra le mieux et de mettre à disposition ce site web
 qui vous permettra, si vous le souhaitez de garder les vidéos de vos apprentissages ainsi que de vos concerts et représentations, 
que vous pourrez alors classer par dates et catégories et par titre et ainsi de conserver un historique de vos progrès. <br></br><br></br>
   Les utilisateurs de ce site découvriront un espace ou le professeur notera leurs objectifs,  si il sont atteint ou non, et pourront faire référence au catalogues de vos vidéos.
   <br></br><br></br>
   N'hésitez pas alors à me contacter par email afin que nous puissions définir ensemble un plan de cours et un agenda.<br></br><br></br>
   
   Nous pouvons aussi travailler ensemble en VISIOCONFERENCE.
   
   
   <br></br><br></br>

Je vous souhaite un bon apprentissage. <br></br><br></br>
<div><img src={piano}className="clavier"></img></div>
</div>

      </div>



     

     

       
       
       
    
       </div> 
  );
}

export default Apropos;
