import { AuthContext } from "../helpers/AuthContext";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Buttoncomp from "./component/Buttoncomp";
import { apiUrl } from "../config";

const CreerPosts = () => {
  let history = useHistory();
  const { authState } = useContext(AuthContext);

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      history.push("/login");
    }
  }, []);

  const [urlenv, setURL] = useState(`${apiUrl}/PublicationPerso`);
  const [Titre, setTitre] = useState("Vidéo");
  const [Redirection, setRedirection] = useState("/PostsPerso");
  const [Chemin, SetChemin] = useState("tirages");
  const [Prof, SetProf] = useState(false);
  const [ListOfUsers, setListOfUsers] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [listOfPosts, setListOfPosts] = useState([]);
  const id = authState.id;

  useEffect(() => {
    axios.get(`${apiUrl}/posts/byuserIdpriv/${id}`).then((response) => {
      const posts = response.data.sort((a, b) => b.id - a.id);
      setListOfPosts(posts);
      setFilteredPosts(posts);
      const categories = posts.map((post) => post.categorie);
      const uniqueCategories = [...new Set(categories)];
      setCategories(uniqueCategories);
    });
  }, [id]);

  useEffect(() => {
    if (id === 1) {
      SetProf(true);
    }
    axios.get(`${apiUrl}/send/list`).then((response) => {
      setListOfUsers(response.data);
    });
  }, [id]);

  const [description, setDescription] = useState("");
  const [text, setText] = useState("");
  const [Categorie, setCategorie] = useState("");
  const [newCategorie, setNewCategorie] = useState(""); // Nouvel état pour la nouvelle catégorie
  const [Username, setUsername] = useState("");
  const [Lien, setLien] = useState("");
  const [Identifiant, setIdentifiant] = useState("");
  const [choixuser, setchoixuser] = useState(false);
  const [requis, setrequis] = useState(false);

  useEffect(() => {
    if (Lien.startsWith("https://www") || Lien.startsWith("https://youtu")) {
      const video = Lien.replace("https://", "");
      setLien(video);
    }
  }, [Lien]);

  const submit = async (event) => {
    try {
      event.preventDefault();

      const formData = new FormData();
      formData.append("lien", Lien);
      formData.append("description", description);
      formData.append("text", text);
      formData.append("UserId", Identifiant);
      formData.append("categorie", Categorie === "new" ? newCategorie : Categorie);
      formData.append("username", Username);

      const result = await axios.post(`${urlenv}`, formData, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      });
      const resultat = await result.data;
      history.push(`${Redirection}`);
    } catch (error) {
      console.error("Une erreur s'est produite lors de la soumission du formulaire :", error);
      alert("Une erreur s'est produite lors de la soumission du formulaire. Veuillez réessayer.");
    }
  };

  const SettingTirages = () => {
    SetChemin("tirages");
    setURL(`${apiUrl}/PublicationPerso`);
    setTitre("Vidéo Personelle");
    setRedirection("/PostsPerso");
    setrequis(false);
    setchoixuser(false);
  };

  const SettingReves = () => {
    SetChemin("reves");
    setURL(`${apiUrl}/Travail`);
    setTitre("Pour l'élève ou votre feed");
    setRedirection("/PostsPage");
    setchoixuser(true);
    setrequis(true);
  };

  return (
    <div>
      <div className="containerpostcreate">
        {Prof && (
          <div className="postpagefeed">
            <div>
              <Buttoncomp
                className="buttonglob_css"
                message={"Creer un posts Vidéo"}
                onClick={() => SettingTirages()}
              />
            </div>
            <div>
              <Buttoncomp
                className="buttonglob_css"
                message={"Creer un post Vidéo pour l'élève ou votre feed"}
                onClick={() => SettingReves()}
              />
            </div>
          </div>
        )}
        <div className="formContainer2">
          <form onSubmit={submit}>
            <div className="titre">
              Créer un post <span className={Titre} id="videospantitre">{Titre}</span>
            </div>
            <label>Titre </label>
            <input
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              type="text"
              maxLength="30"
              required
              placeholder="(ex: Recherche 1 , piano test etc...) Ce Champ est obligatoire et permet d'effectuer une recherche par Titre"
            />
            <label>Post </label>
            <textarea
              value={text}
              onChange={(i) => setText(i.target.value)}
              type="text"
              id="text"
              minLength="0"
              maxLength="800"
              size="10"
              placeholder="(ex: Voici mon post !) Ce Champ n'est pas obligatoire"
            />
            <label>Catégorie </label>
            <select
              value={Categorie}
              onChange={(e) => setCategorie(e.target.value)}
              id="Categorie"
            >
              <option value="">Sélectionnez une catégorie</option>
              {categories.map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
              <option value="new">Ajouter une nouvelle catégorie</option>
            </select>
            {Categorie === "new" && (
              <input
                value={newCategorie}
                onChange={(d) => setNewCategorie(d.target.value)}
                type="text"
                id="newCategorie"
                minLength="0"
                maxLength="30"
                size="10"
                placeholder="Entrez une nouvelle catégorie"
              />
            )}

            {Prof && ListOfUsers && choixuser && (
              <>
                <label>Identifiant </label>
                <select
                  value={Identifiant}
                  onChange={(f) => setIdentifiant(f.target.value)}
                  required
                >
                  <option value="" disabled>
                    Sélectionnez un utilisateur
                  </option>
                  {ListOfUsers.map((user) => (
                    <option key={user.id} value={user.id} onChange={() => setIdentifiant(user.id)}>
                      {user.username} : {user.email}
                    </option>
                  ))}
                </select>
              </>
            )}
            <label>Lien de la vidéo </label>
            <input
              value={Lien}
              onChange={(e) => setLien(e.target.value)}
              id="lien"
              type="text"
              required
              placeholder="(ex:http://www.youtube.com) Ce champ est obligatoire"
            />

            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreerPosts;
