import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import { apiUrl } from "../../../config";
import Swiper from "swiper";
import axios from "axios";

export const Slideaccueil = ({ urlplay }) => {
  const [MyId, setMyId] = useState(1);
  const swiperRef = useRef(null);
  const playerRefs = useRef([]);
  const [valueslice, setvalueslice] = useState(5);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [listOfPosts, setListOfPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]); // Nouvel état pour les posts filtrés/mélangés
  const [isRandom, setIsRandom] = useState(false); // État pour le bouton

  const myplayerValue = localStorage.getItem("myplayer");
  const myplayerId = localStorage.getItem("myid");
  const [Myplayer, setMyplayer] = useState("byuserIdpriv");
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const slideWidth = 300;

  useEffect(() => {
    if (myplayerValue !== null) {
      setMyplayer(myplayerValue);
      setMyId(myplayerId);
    }

    axios.get(`${apiUrl}/${urlplay}/${Myplayer}/${MyId}`)
      .then((response) => {
        const posts = response.data.sort((a, b) => b.id - a.id); // Tri initial
        setListOfPosts(posts);
        setFilteredPosts(posts); // Initialiser filteredPosts avec l'ordre trié
        const categories = posts.map(post => post.categorie);
        const uniqueCategories = [...new Set(categories)];
        setCategories(uniqueCategories);
      });
  }, [MyId, Myplayer, myplayerId, myplayerValue, urlplay]);

  useEffect(() => {
    swiperRef.current = new Swiper(".swiper-container", {
      slidesPerView: "auto",
      centeredSlides: true,
      spaceBetween: 300,
      navigation: {
        nextEl: ".swiper-button-next.custom-next-button",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true,
        clickable: true,
      },
      on: {
        slideNextTransitionEnd: function () {
          if (this.isEnd) {
            goToNextVideo();
          }
        },
        slidePrevTransitionEnd: function () {
          if (this.isBeginning) {
            goTopreviousVideo();
          }
        },
      },
    });
  }, []);

  const goToNextVideo = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
      setvalueslice(valueslice + 3);
    }
  };

  const goTopreviousVideo = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const shuffleArray = (array) => {
    let shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  const toggleRandom = () => {
    if (isRandom) {
      // Retour à l'ordre initial basé sur searchTerm
      setFilteredPosts(listOfPosts.filter(post => searchTerm === "" || post.categorie === searchTerm));
    } else {
      setFilteredPosts(shuffleArray(filteredPosts));
    }
    setIsRandom(!isRandom);
  };

  const handleSearchTerm = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    setFilteredPosts(listOfPosts.filter(post => newSearchTerm === "" || post.categorie === newSearchTerm));
  };

  const pauseOtherVideos = (currentIndex) => {
    playerRefs.current.forEach((player, index) => {
      if (index !== currentIndex && player) {
        player.getInternalPlayer().pauseVideo();
      }
    });
  };

  return (
    <>
      <div className="searchbarvideo">
        <select value={searchTerm} onChange={handleSearchTerm}>
          <option value="">Catégories</option>
          {categories.map((category, index) => (
            <option key={index} value={category}>{category || "Sans catégorie"}</option>
          ))}
        </select>
      </div>
      <div className="button_previous_and_next">
        <button onClick={() => swiperRef.current.slidePrev()}>Précédent</button>
        <button onClick={toggleRandom}>
          {isRandom ? "Liste" : "Aléatoire"}
        </button>
        <button onClick={goToNextVideo}>Suivant</button>
   
      </div>

      <section className="sectionaccueil">
        <div className="swiper-container">
          <div className="swiper-wrapper">
            {filteredPosts.slice(0, valueslice).map((value, key) => {
              return (
                <div
                  key={key}
                  className="swiper-slide"
                  data-swiper-slide-index="1"
                  style={{ width: slideWidth }}
                >
                  <div>
                    <ReactPlayer
                      borderRadius={"10%"}
                      width={"100%"}
                      height={"100%"}
                      ref={(ref) => (playerRefs.current[key] = ref)}
                      className="reactplayer"
                      playing={key === currentVideoIndex}
                      key={key}
                      preload="auto"
                      url={`https://${value.lien}`}
                      playerVars={{
                        autoplay: 1,
                        modestbranding: 1,
                        showinfo: 0,
                        rel: 0,
                      }}
                      controls
                      onEnded={() => {
                        goToNextVideo(key);
                        setCurrentVideoIndex(key + 1);
                      }}
                      onPlay={() => {
                        setCurrentVideoIndex(key);
                        setIsPlaying(true);
                        pauseOtherVideos(key);
                      }}
                      onPause={() => setIsPlaying(false)}
                      onUnstarted={() => {
                        if (playerRefs.current[currentVideoIndex]) {
                          playerRefs.current[currentVideoIndex].getInternalPlayer().playVideo();
                        }
                      }}
                    />
                  </div>

                  <div className="description">
                    <div className="titre">{value.title}</div>
                    <div className="bodydescritpion">{value.postText}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Slideaccueil;
