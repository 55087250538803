

const Loader = () => {


return (
  //  ["lds-roller"] est utiliser pour etirer le . car ce sont des modules Css qui sont utilisés

<span className="lds-roller">
  <div className="white">....</div>
  <div className="black"></div>
  <div className="white">....</div>
  <div className="black"></div>
  <div className="white">....</div>
  <div className="black"></div>
  <div className="white">....</div>
  <div className="black"></div>
  <div className="white">....</div>
  <div className="black"></div>
  <div className="white">....</div>
  
  
  </span>

)

};


export default Loader;