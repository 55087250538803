import React, { useState, useRef, useEffect, useContext } from "react";
import ReactPlayer from 'react-player';
import { apiUrl } from "../../../config";
import Swiper from 'swiper';
import axios from "axios";
import { AuthContext } from "../../../helpers/AuthContext";
export const SlideProf = ({urlplay}) => {
    const { authState } = useContext(AuthContext); 
    const MyId = authState.id;

    const swiperRef = useRef(null);
    const playerRefs = useRef([]);
     //Nombre de diapositives au démarrage
    const [valueslice, setvalueslice] = useState(5);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [videoStates, setVideoStates] = useState({});
    const [listOfPosts, setListOfPosts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");


const slideWidth = 300; 
  
useEffect(() => {
  axios.get(`${apiUrl}/${urlplay}/${1}`)
      .then((response) => {
          setListOfPosts(response.data);
          const categories = response.data.map(post => post.categorie);
          const uniqueCategories = [...new Set(categories)];
          setCategories(uniqueCategories);
      });
}, [MyId]);
      
      
      useEffect(() => {
        swiperRef.current = new Swiper(".swiper-container", {
          slidesPerView: 'auto',
          centeredSlides: true,
          spaceBetween: 300, // Espacement entre les diapositives
          navigation: {
            nextEl: ".swiper-button-next.custom-next-button", 
            prevEl: ".swiper-button-prev",
          },
          pagination: {
            el: ".swiper-pagination",
            dynamicBullets: true,
            clickable: true,
          },
          on: {
            slideNextTransitionEnd: function() {
              if (this.isEnd) {
                goToNextVideo();
              }
            },
            slidePrevTransitionEnd: function() {
              if (this.isBeginning) {
                goTopreviousVideo();
              }
            } 
          }
        });
    }, [])
      
    const goToNextVideo = () => {
        if (swiperRef.current) {
         
          swiperRef.current.slideNext();
          setvalueslice(valueslice + 1);
        
        }
      };
    
    const goTopreviousVideo = () => {
        if (swiperRef.current) {
         
          swiperRef.current.slidePrev()
         /*  setvalueslice(valueslice - 1); */
        
        }
      };
      


      const orderDisplayPosts = 
      listOfPosts && listOfPosts.sort((a, b) => b.id - a.id);

  const orderDisplayPostsSlice = orderDisplayPosts.slice(0, valueslice);

  function pauseOtherVideos(currentIndex) {
      playerRefs.current.forEach((player, index) => {
          if (index !== currentIndex && player) {
              player.getInternalPlayer().pauseVideo();
          }
      });
  } 

  const handleSearchTerm = (e) => {
      setSearchTerm(e.target.value);
  };




  const filteredPosts = orderDisplayPostsSlice.filter(post => {
      if (searchTerm === "") {
          return true;
      } else {
          return post.categorie === searchTerm;
      }
  });

  return (
    <>
        <div className="searchbarvideo"> 
            <select value={searchTerm} onChange={handleSearchTerm}>
                <option value="">Catégories</option>
                {categories.map((category, index) => (
                    <option key={index} value={category}>{category || "Sans catégorie"}</option>
                ))}
            </select>
        </div>

        <div className="button_previous_and_next">
            <button onClick={() => swiperRef.current.slidePrev()}>Précédent</button>
            <button onClick={goToNextVideo}>Suivant</button>
        </div>

        <section className="sectionaccueil">
            <div className="swiper-container">
                <div className="swiper-wrapper">
                    {filteredPosts.map((value, key) => (
                        <div key={key} className="swiper-slide" data-swiper-slide-index="1" style={{ width: slideWidth }}>
                            <div>
                                <ReactPlayer
                                    width={"100%"}
                                    height={"100%"}
                                    ref={ref => playerRefs.current[key] = ref}
                                    className="reactplayer"
                                    playing={key === currentVideoIndex}
                                    key={key} 
                                    preload={key === 0 ? "auto" : "metadata"}
                                    url={`https://${value.lien}`}
                                    playerVars={{
                                        autoplay: 1,
                                        modestbranding: 1,
                                        showinfo: 0,
                                        rel: 0
                                    }}
                                    controls
                                    onEnded={() => {
                                        goToNextVideo(key);
                                        setCurrentVideoIndex(key + 1);
                                    }}
                                    onPlay={() => {
                                        setCurrentVideoIndex(key);
                                        setIsPlaying(true);
                                        pauseOtherVideos(key);
                                    }}
                                    onPause={() => setIsPlaying(false)}
                                />
                            </div>
                            <div className="description">
                                <div className="titre">{value.title}</div>
                                <div className="bodydescritpion">{value.postText}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    </>
);
}

export default SlideProf